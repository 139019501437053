import { Link, Meta, Spacer } from '@bedrockio/pages';
import { useClass } from '@bedrockio/pages/utils';

import Header from 'components/Header';
import Button from 'components/Button';
import Container from 'components/Container';

import './coverage.less';

export default function Coverage() {
  const { getElementClass } = useClass('about');

  return (
    <div>
      <Meta>
        <title>Coverage</title>
      </Meta>
      <Header />
      <Spacer size="lg" />
      <Container>
        <h1 className="lg">Coverage</h1>
        <Spacer size="sm" />
        <p>
          Search your location and see how Free.Mobile has you covered with our
          Nationwide Cell Phone Service
        </p>
        <Spacer size="md" />
        <iframe
          src="https://getsavemobile.com/coverage-map"
          className={getElementClass('frame')}
          style={{
            border: 'none',
          }}
          cellSpacing="0"
          width="100%"
        />
        <Spacer />
        <Button primary as={Link} to="/checkout">
          Get Started Today
        </Button>
      </Container>
      <Spacer size="xl" />
    </div>
  );
}
