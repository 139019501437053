import { Link, ExternalLink } from '@bedrockio/pages';

import facebook from 'assets/images/facebook.svg';
import instagram from 'assets/images/instagram.svg';

import './footer.less';

export default function Footer() {
  return (
    <footer className="footer right">
      <ExternalLink href="https://www.facebook.com/profile.php?id=61556070281075">
        <img src={facebook} />
      </ExternalLink>
      <ExternalLink href="https://www.instagram.com/getfreemobile">
        <img src={instagram} />
      </ExternalLink>
      <Link to="/terms">Terms</Link>
      <Link to="/privacy">Privacy</Link>
    </footer>
  );
}
