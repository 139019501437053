import React from 'react';
import { Link, Meta, Spacer } from '@bedrockio/pages';

import Header from 'components/Header';
import Container from 'components/Container';

export default function Error() {
  return (
    <React.Fragment>
      <Meta>
        <title>Error</title>
      </Meta>
      <Spacer />
      <Header />
      <Container>
        <Spacer size="xl" />
        <h1 className="center">Something went wrong!</h1>
        <Spacer size="md" />
        <p className="center">
          <Link to="/">Go Home</Link>
        </p>
        <Spacer size="xl" />
      </Container>
    </React.Fragment>
  );
}
