import {
  Meta,
  Spacer,
  Image,
  Icon,
  Link,
  ExternalLink,
} from '@bedrockio/pages';
import { useClass, onMount } from '@bedrockio/pages/utils';

import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';
import Container from 'components/Container';

import home1 from 'assets/images/sets/home1';
import home2 from 'assets/images/sets/home2';
import home3 from 'assets/images/sets/home3';

import './home.less';

import appStoreBadge from 'assets/images/app-store-badge.svg';
import playStoreBadge from 'assets/images/google-play-badge.svg';

export default function Home() {
  const { getElementClass } = useClass('home');

  onMount(() => {
    checkReferer();
  });

  return (
    <div>
      <Meta>
        <title>Home</title>
      </Meta>
      <Header />
      <Spacer size="lg" />
      <Container>
        <div className={getElementClass('hero')}>
          <div className={getElementClass('hero-left')}>
            <h1 className={getElementClass('copy')}>
              Unleash Unlimited Data For Free
            </h1>
            <div className={getElementClass('app-store-links')}>
              <ExternalLink href="https://play.google.com/store/apps/details?id=ai.rekall.freemobile">
                <img src={playStoreBadge} height="62" />
              </ExternalLink>
              <ExternalLink href="https://apps.apple.com/us/app/free-mobile/id6502416831">
                <img src={appStoreBadge} height="62" />
              </ExternalLink>
            </div>
            <div>
              <Button primary as={Link} to="/checkout">
                Sign Up Today
              </Button>
            </div>
          </div>
          <div className={getElementClass('hero-right')}>
            <Image set={home1} className={getElementClass('hero-image')} />
          </div>
        </div>
        <Spacer size="xl" />
        <div id="about" className={getElementClass('home2')}>
          <Image set={home2} className={getElementClass('home2-image')} />
          <div className={getElementClass('home2-body')}>
            <h3>About</h3>
            <Spacer size="md" />
            <p>
              Free.Mobile is offering unlimited mobile data plans—for FREE! Just
              complete our entertaining surveys, watch engaging ads or refer a
              friend to keep your data flowing.
            </p>
            <Spacer size="md" />
            <div>
              <Button primary as={Link} to="/checkout">
                Activate Your Free Plan
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <Spacer size="xl" />
      <div id="how-it-works" className={getElementClass('works')}>
        <Container>
          <Spacer size="lg" />
          <h1 className="lg">How It Works</h1>
          <Spacer />
          <div className={getElementClass('grid')}>
            <div className={getElementClass('grid-item')}>
              <div className={getElementClass('grid-icon')}>
                <Icon name="clipboard" />
              </div>
              <div className={getElementClass('grid-body')}>
                Complete fun surveys to unlock credits for{' '}
                <em>free coverage</em> conveniently through the free.mobile app.
              </div>
            </div>
            <div className={getElementClass('grid-item')}>
              <div className={getElementClass('grid-icon')}>
                <Icon name="eye" />
              </div>
              <div className={getElementClass('grid-body')}>
                Watch engaging ads to unlock credits for <em>free coverage</em>{' '}
                conveniently through the free.mobile app.
              </div>
            </div>
            <div className={getElementClass('grid-item')}>
              <div className={getElementClass('grid-icon')}>
                <Icon name="refer" />
              </div>
              <div className={getElementClass('grid-body')}>
                Refer a friend to unlock credits for <em>free coverage</em>{' '}
                conveniently through the free.mobile app.
              </div>
            </div>
          </div>
          <Spacer size="lg" />
          <Button as={Link} to="/checkout">
            Get Started Today
          </Button>
          <Spacer size="xl" />
        </Container>
      </div>
      <div className={getElementClass('home3')}>
        <Image set={home3} className={getElementClass('home3-image')} />
        <div className={getElementClass('home3-bottom')}>
          <Spacer size="md" />
          <div className={getElementClass('home3-text')}>
            CALLING, STREAMING, BROWSING, TEXTING...
          </div>
          <Spacer size="sm" />
          <Button primary outline round as={Link} to="/checkout">
            Sign Up Today
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function checkReferer() {
  const params = new URLSearchParams(location.search);
  const referer = params.get('referer');
  if (referer) {
    localStorage.setItem('referer', referer);
  }
}
