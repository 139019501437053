import { useState } from 'react';
import { useLocation, Spacer, Spinner, Meta } from '@bedrockio/pages';
import { useClass, request, onMount } from '@bedrockio/pages/utils';

import Header from 'components/Header';
import Container from 'components/Container';

import './validate.less';

export default function Validate() {
  const { className } = useClass('validate');
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [verified, setVerified] = useState(false);

  onMount(() => {
    validateToken();
  });

  async function validateToken() {
    try {
      setError(null);
      setLoading(true);
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      if (!token) {
        throw new Error('No token found');
      }
      await request({
        method: 'POST',
        path: '/1/signup/validate',
        token,
      });
      setLoading(false);
      setVerified(true);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  return (
    <div className={className}>
      <Meta>
        <title>Validate Email</title>
      </Meta>
      <Header />
      <Container>
        <Spacer size="xl" />
        {error && <div className="error">{error.message}</div>}
        {verified && (
          <div className="center">
            <p>Your email was verified.</p>
            <p>
              It should take <b>5 business days</b> for your SIM card to arrive.
            </p>
          </div>
        )}
        {loading && <Spinner />}
      </Container>
    </div>
  );
}
