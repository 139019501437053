import { useEffect } from 'react';
import { Icon, ErrorBoundary, useLocation } from '@bedrockio/pages';

import ErrorPage from 'pages/Error';
import icons from 'assets/icons.svg';

import Router from './Router';

import './app.less';

Icon.useSet(icons);

const ALTERNATES = ['/how-it-works', '/checkout'];

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    const isAlternate = ALTERNATES.includes(pathname);
    document.documentElement.classList.toggle('alternate', isAlternate);
  }, [pathname]);

  const className = ALTERNATES.includes(pathname) ? 'alternate' : '';
  return (
    <main className={className}>
      <ErrorBoundary fallback={<ErrorPage />}>
        <Router />
      </ErrorBoundary>
    </main>
  );
}
